// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-expandable',
//   templateUrl: './expandable.component.html',
//   styleUrls: ['./expandable.component.scss'],
// })
// export class ExpandableComponent implements OnInit {

//   constructor() { 
//     console.log("hello")
//   }

//   ngOnInit() {}

// }
import { Component, AfterViewInit, Input, ViewChild,  ElementRef, Renderer2 } from "@angular/core";

@Component({
  selector: "app-expandable",
  templateUrl: "./expandable.component.html",
  styleUrls: ["./expandable.component.scss"]
})
export class ExpandableComponent implements AfterViewInit {
  @ViewChild("expandWrapper", { read: ElementRef }) expandWrapper: ElementRef;
  @Input("expanded") expanded: boolean = false;
  @Input("expandHeight") expandHeight: string = "287px";

  constructor(public renderer: Renderer2) {}

  ngAfterViewInit() {
    this.renderer.setStyle(this.expandWrapper.nativeElement, "max-height", this.expandHeight);
  }
}